import SourceFlowHead from "@sourceflow-uk/sourceflow-head";
import metaObject from "@/../.sourceflow/metadata.json";
import nextConfig from "@/../next.config"
import { useMemo } from "react";

const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
const SITE_NAME = process.env.NEXT_PUBLIC_SITE_NAME;
const SITE_DESCRIPTION = process.env.NEXT_PUBLIC_SITE_DESCRIPTION;
const TWITTER_HANDLE = process.env.NEXT_PUBLIC_TWITTER_HANDLE;

export const defaultProps = {
  metaObject,
  siteName: SITE_NAME,
  title: SITE_NAME,
  titleTemplate: `%s · ${SITE_NAME}`,
  defaultTitle: SITE_NAME,
  description: SITE_DESCRIPTION,
  canonical: BASE_URL,
  images: [
    {
      url: "/og-image.webp",
    },
  ],
  twitter: {
    site: TWITTER_HANDLE,
    cardType: "summary_large_image",
  },
};

export default function SeoHead({ canonical: __canonical = "", ...props }) {
  const canonical = useMemo(
    () => {
      let url =  __canonical.startsWith("http") ? __canonical : `${BASE_URL}${__canonical}`;
      return nextConfig.trailingSlash && !url.endsWith('/') ? `${url}/` : url;
    },
    [__canonical],
  );

  return <SourceFlowHead {...defaultProps} {...props} canonical={canonical} />;
}