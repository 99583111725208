import { Content } from "@/ui";
import SeoHead from "@/components/SeoHead";
import { blogs_helper } from "@/helpers/blogs_helper";
import { talent_solution_helper } from "@/helpers/talent_solution_helper";
import { projects_helper } from "@/helpers/projects_helper";
import { jobs_helper } from "@/helpers/jobs_helper";

export default function Page({ title, content }) {
  return (
    <>
      <SeoHead title={title} />
      <Content items={content} />
    </>
  );
}

export async function getStaticProps({}) {
  const jobs = jobs_helper.fetch();

  return {
    props: {
      title: "Insight Recruitment",
      content: [
        {
          component: "HomeHero",
          props: {
            title: {
              path: "home.hero.title",
              placeholder: "Tech and Mission Critical Recruitment",
            },
            video: "/videos/home_hero.mp4",
            jobs,
          },
        },
        {
          component: "ContentSection",
          props: {
      ishome:true,
            title: {
              path: "home.content.title",
              placeholder:
                "Insight Recruitment are independent, forward-thinking and professional recruitment specialists based in the UK",
            },
            subtitle: {
              path: "home.content.subtitle",
              placeholder: "What we do",
            },
            description: {
              path: "home.content.body",
              placeholder:
                "We empower growth sustainably by providing tailored Contract, Permanent and Search solutions across our Tech & Mission Critical divisions. Our vision is to be the recruitment partner of choice for everything from agile start up’s through to global organisations and Engineers and CTO’s alike. At Insight, we work in partnership to connect the best people with the most exciting companies in the industry, whilst always ensuring we are adding value along the way.",
            },
          },
        },
        // {
        //   component: "VideoSection",
        //   props: {
        //     video: "https://www.youtube.com/embed/E5YX3D-4EbA?si=8gjIECg55gONy7Im&amp;controls=0",
        //   }
        // },
        {
          component: "SectorSection",
          props: {
            tech: {
              url_slug: "/sectors/technology",
              title: {
                path: "home.sectors.tech.title",
                placeholder: "A modern approach to Tech Recruitment",
              },
              subtitle: {
                path: "home.sectors.tech.subtitle",
                placeholder: "Insight Into:",
              },
              image: {
                path: "home.sectors.tech.image",
              },
            },
            mission: {
              url_slug: "/sectors/mission-critical",
              title: {
                path: "home.sectors.mission.title",
                placeholder: "A modern approach to Mission Critical",
              },
              subtitle: {
                path: "home.sectors.mission.subtitle",
                placeholder: "Insight Into:",
              },
              image: {
                path: "home.sectors.mission.image",
              },
            },
          },
        },
        {
          component: "SolutionSection2",
          props: {
            titlePath: "technology.solution_title",
            talent: talent_solution_helper.fetch({}),
          },
        },
        // {
        //   component: "SolutionSection",
        //   props: {
        //     titlePath: "technology.solution_title",
        //     items: [
        //       { imagePath: "technology.solution_1", path: "technology.solution_1" },
        //       { imagePath: "technology.solution_2", path: "technology.solution_2" },
        //       { imagePath: "technology.solution_3", path: "technology.solution_3" },
        //       { imagePath: "technology.solution_4", path: "technology.solution_4" },
        //     ],
        //   },
        // },
        {
          component: "ProjectsSection",
          props: {
            title: {
              path: "home.projects.title",
              placeholder: "Projects",
            },
            projects: projects_helper.fetch({ limit: 3 }) || [],
          },
        },
        {
          component: "WorkWithUs",
          props: {
            title: {
              path: "home.workwithus.title",
              placeholder: "Work with us - Insight Recruitment operates globally",
            },
            image: {
              path: "home.workwithus.image",
            },
            button: {
              title: "VIEW VACANCIES",
              href: "/jobs",
            },
          },
        },
        {
          component: "BlogSection",
          props: {
            title: {
              path: "home.blogs.title",
              placeholder: "Check out our blog for more insights and updates",
            },
            subtitle: {
              path: "home.blogs.subtitle",
              placeholder: "Latest News",
            },
            blogs: blogs_helper.fetch({ limit: 2 }),
          },
        },
      ],
    },
  };
}
